import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

import P from "../../atoms/P"
import HeaderText from "../../atoms/HeaderText"
import useMediaType from "../../hooks/useMediaType"

const Container = styled.div`
  display: flex;
  width: 100%;
  background-color: ${props => props.theme.colors.site.primaryDark};
`

const ContentContainer = styled.div`
  display: flex;
  //mobile

  flex-direction: column-reverse;
  width: 100%;

  p {
    line-height: 20px;
  }
  //tablet
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    width: 100%;
    flex-direction: column-reverse;
    width: 100%;
    p {
      line-height: 24px;
    }
  }

  //desktop small
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmallMin}px) {
    flex-direction: row;

    width: 100%;
    p {
      line-height: 24px;
    }
    justify-content: center;
  }
`
const ImageWrapper = styled.div`
  width: 100%;
  margin: 10px auto 45px;

  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    margin-top: 49px;
    margin-bottom: 78px;
    max-width: 418px;
  }

  //desktop small
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmallMin}px) {
    max-width: 494px;
    margin: 40px -90px 30px 0;
  }
`
const TextWrapperBannerContact = styled.div`
  margin: 65px 28px 13px 28px;
  p {
    &:first-of-type {
      margin-bottom: 32px;
    }
    line-height: 20px;
  }
  //tablet
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    margin-top: 56px;
    margin-bottom: 0px;
    margin-left: auto;
    margin-right: auto;
    width: 504px;
    p {
      &:first-of-type {
        margin-bottom: 32px;
      }
    }
    p {
      line-height: 24px;
    }
  }

  //desktop small
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.desktopSmallMin}px) {
    margin-left: 131px;
    margin-right: 131px;
    margin-top: 52px;
    width: 401px;
    p {
      &:first-of-type {
        margin-bottom: 40px;
      }
    }
  }
`
const ParagraphWrapper = styled.div`
  p {
    font-size: 14px;
  }
  //tablet
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    p {
      font-size: 16px;
    }
  }
`

const BannerContact = ({ data }) => {
  const mediaType = useMediaType()

  const headerSizeHandler = media => {
    switch (media) {
      case "desktop":
        return 1
      case "desktopSmall":
        return 2
      case "tablet":
        return 2
      case "mobile":
        return 2
      default:
    }
  }
  const paragraphSizeHandler = media => {
    switch (media) {
      case "desktop":
        return "medium"
      case "desktopSmall":
        return "medium"
      case "tablet":
        return "medium"
      case "mobile":
        return "small"
      default:
    }
  }
  const [PSize, setPSize] = useState()
  const [HSize, setHSize] = useState()

  useEffect(() => {
    setPSize(paragraphSizeHandler(mediaType))
    setHSize(headerSizeHandler(mediaType))
  }, [mediaType])

  return (
    <Container className="contactbannertext">
      <ContentContainer>
        <ImageWrapper>
          <GatsbyImage
            image={data.image.childImageSharp.gatsbyImageData}
            loading={"eager"}
          />
        </ImageWrapper>

        <TextWrapperBannerContact>
          <HeaderText size={HSize} color="white">
            {data.header}
          </HeaderText>
          <ParagraphWrapper>
            <P size={PSize} color="white">
              {data.text_1}
            </P>
            <P size={PSize} color="white">
              {data.text_2}
            </P>
          </ParagraphWrapper>
        </TextWrapperBannerContact>
      </ContentContainer>
    </Container>
  )
}

export default BannerContact
