import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import BannerContact from "../components/organisms/BannerContact"
import ContactForm from "../components/organisms/ContactForm"
import Layout from "../components/Layout"
import Seo from "../components/seo"

const Contact = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { type: { eq: "contact_page" } } }
        ) {
          edges {
            node {
              frontmatter {
                banner {
                  header
                  text_1
                  text_2
                  image {
                    childImageSharp {
                      gatsbyImageData(placeholder: NONE, width: 494)
                    }
                  }
                }
                form {
                  question
                  area_icon_path
                  branches
                  press_page
                }
              }
            }
          }
        }
      }
    `
  )

  return (
    <Layout>
      <Seo title="Contact" />
      {allMarkdownRemark.edges.map((item, i) => {
        let content = item.node.frontmatter

        return (
          <>
            <BannerContact data={content.banner} />
            <ContactForm data={content.form} />
          </>
        )
      })}
    </Layout>
  )
}

export default Contact
