import React from "react"
import styled from "styled-components"
import { Script } from "gatsby"

import P from "../../atoms/P"
import HeaderText from "../../atoms/HeaderText"

const Container = styled.div`
  background: #ffffff;
  height: 100%;
  width: 100%;
  input:not([type="radio"]):not([type="checkbox"]) {
    -webkit-appearance: none;
    border-radius: 0;
  }
  textarea {
    -webkit-appearance: none;
    border-radius: 0;
  }
`

const FormContainer = styled.div`
  max-width: 798px;
  margin-top: 100px;
  margin-inline: auto;

  .hs-input {
  }

  .hs-dependent-field {
  }

  .hs-error-msg {
  }

  input[type="email"].hs-input {
  }

  input[type="text"].hs-input {
  }

  input[type="email"].hs-input.error {
  }

  input[type="text"].hs-input.error {
  }

  .hs-richtext {
  }

  .hs-form-field {
  }

  .hs-form .input {
  }

  input[type="text"].hs-input {
  }

  input[type="email"].hs-input {
  }

  .legal-consent-container .field.hs-form-field {
  }

  .hs-form-booleancheckbox {
  }

  input[type="checkbox"].hs-input {
  }

  .hs-submit {
  }
`

const BranchContainer = styled.div`
  max-width: 504px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 72px auto 25px auto;
  //mobile
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    flex-direction: row;
  }
`
const Branch = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: -12px;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    margin-bottom: 0px;
  }
`

const BranchImage = styled.div`
  background-image: url(${props => props.image});
  width: 20px;
  height: 24px;
  display: none;
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    display: flex;
  }
`

const PressContactContainer = styled.div`
  justify-content: center;
  text-align: center;
  margin-bottom: 66px;
  margin-left: 28px;
  margin-right: 29px;

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: ${props => props.theme.colors.site.secondaryLight};
    }
  }

  @media only screen and (max-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    margin-left: 0;
    margin-right: 0;
  }
`
const ContactForm = ({ data }) => {
  return (
    <Container>
      <FormContainer>
        <Script
          src="//js.hsforms.net/forms/embed/v2.js"
          strategy="idle"
          onLoad={() => {
            if (window.hbspt) {
              window.hbspt.forms.create({
                portalId: "26220204",
                formId: "40a790f6-6ca4-45f7-8bec-51248a3341ec",
              })
            }
          }}
        />
        <script>
          {`
            hbspt.forms.create({
              region: "eu1",
              portalId: "26220204",
              formId: "40a790f6-6ca4-45f7-8bec-51248a3341ec"
            });
          `}
        </script>
      </FormContainer>
      <BranchContainer>
        {data.branches.map(branch => (
          <Branch>
            <BranchImage image={data.area_icon_path.split("path:")[1]} />
            <HeaderText color="text" size="medium">
              {branch}
            </HeaderText>
          </Branch>
        ))}
      </BranchContainer>
      <PressContactContainer>
        <P size="medium">
          For media inquires:{" "}
          <a href="mailto:press@norberthealth.com">press@norberthealth.com</a>
        </P>
      </PressContactContainer>
    </Container>
  )
}

export default ContactForm
